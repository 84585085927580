@import 'react-ftm/variables.scss';

.EntitySelect {
  .#{$bp-ns}-popover2-content,
  .#{$bp-ns}-menu {
    background: white !important;
    color: $black-70 !important;
    fill: $black-70 !important;
  }
}
