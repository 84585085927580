@import 'app/variables.scss';

.DownloadButton {
  &__checkbox-container {
    margin: $aleph-grid-size * 2 0;

    .#{$bp-ns}-checkbox {
      margin: 0;
    }
  }
}
