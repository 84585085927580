@import 'app/mixins.scss';

.AuthenticationScreen.AuthenticationScreen {
  width: 28em !important;
  padding-bottom: 0;

  .menu-divider {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .signin-button {
    @include rtl(float, right, left);
  }

  .hide {
    display: none;
  }

  .link-box {
    // TODO: This is a hacky way to center the link horizontally. We should
    // refactor this and use flexbox to achieve that.
    margin-top: 1.75em;
  }
}
