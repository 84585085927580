@import 'app/variables.scss';

.Screen {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  background: $aleph-page-background;

  main {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    height: 100%;
  }
}
