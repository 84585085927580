@import 'src/react-ftm/variables.scss';

.Count {
  &.#{$bp-ns}-tag {
    font-weight: 600;
  }
  &.no-fill {
    background: none !important;
  }

  &.#{$bp-ns}-intent-primary,
  .#{$bp-ns}-tab[aria-selected='true'] > & {
    background-color: $pt-intent-primary !important;
    color: white !important;
  }
}
