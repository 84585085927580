@import 'app/variables.scss';
@import 'app/mixins.scss';

$metadata-width: $aleph-investigation-sidebar-width;
$metadata-padding: $aleph-content-padding;
$default-breakpoint: $aleph-screen-sm-max-width;
$casefile-breakpoint: $aleph-screen-md-max-width;

@mixin foldup {
  @media screen and (max-width: $default-breakpoint) {
    @content;
  }
  &.casefile,
  .casefile & {
    @media screen and (max-width: $casefile-breakpoint) {
      @content;
    }
  }
}

.CollectionOverviewMode {
  display: flex;
  flex-flow: row;
  width: 100%;

  @include foldup {
    flex-direction: column-reverse;
  }

  &__main,
  &__secondary {
    @include foldup {
      width: 100% !important;
    }
  }

  &__main {
    width: calc(100% - #{$metadata-width} - #{$metadata-padding});
  }

  &__secondary {
    width: $metadata-width;
    @include rtlSupportInvertedProp(margin, left, $metadata-padding, 0);
    @include rtlSupportInvertedProp(padding, left, $metadata-padding, 0);
    @include rtlSupportInvertedProp(
      border,
      left,
      1px solid $aleph-border-color,
      none
    );

    @include foldup {
      padding: 0 0 $metadata-padding !important;
      margin: 0 !important;
      border: none !important;
    }
  }
}
