@import 'app/variables.scss';

$top-nav-height: 50px;
$breadcrumb-height: 44px;

.DiagramEditor {
  .NetworkDiagram {
    height: calc(100vh - #{$top-nav-height + (2 * $breadcrumb-height)});
    background: white;
    overflow: hidden;
  }

  .Sidebar__search-text {
    font-style: normal;
  }
}
