@import 'app/variables.scss';
@import 'app/mixins.scss';

.DateFacet {
  &.#{$bp-ns}-card {
    padding-top: $aleph-grid-size * 2;
    padding-bottom: $aleph-grid-size * 0.5;
    position: relative;
  }

  &__label {
    padding-bottom: $aleph-grid-size;
    font-size: 1.1em;
    font-weight: bold;
  }

  .#{$bp-ns}-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__secondary {
    margin-bottom: $aleph-grid-size * 0.5;
    @include rtl(text-align, right, left);

    &,
    .#{$bp-ns}-button-text {
      font-size: 12px;
    }

    .#{$bp-ns}-button-text:hover {
      text-decoration: underline;
    }

    .#{$bp-ns}-button {
      display: contents;
    }
  }

  &__parent-label {
    font-weight: bold;
    color: $blue2;
    margin-bottom: $aleph-grid-size * 0.5;
  }

  .ErrorSection {
    min-height: unset;

    .#{$bp-ns}-non-ideal-state {
      .#{$bp-ns}-heading {
        font-size: 16px;
      }
    }
  }
}
