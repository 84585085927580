@import 'app/variables.scss';

.ImageViewer {
  // text-align: center;
  margin-bottom: 2 * $aleph-grid-size;

  pre {
    // padding: $aleph-grid-size;
  }

  img {
    max-width: 100%;
    // max-height: 100vh;
    height: auto;
  }
}
