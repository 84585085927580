@import 'app/variables.scss';

.AdvancedSearchMultiField {
  &__list {
    margin-bottom: $aleph-grid-size;

    &__item {
      margin: $aleph-grid-size * 0.5 $aleph-grid-size $aleph-grid-size * 0.5 0;
    }
  }

  &__form {
    border: 1px solid $aleph-border-color;
    border-radius: $aleph-grid-size * 0.5;
    padding: 8px;
    margin-top: $aleph-grid-size;

    .#{$bp-ns}-form-group {
      margin-bottom: 0;
      align-self: flex-end;
    }

    .#{$bp-ns}-button {
      width: $aleph-grid-size !important;
      max-width: $aleph-grid-size !important;
      flex-basis: $aleph-grid-size !important;
      margin-left: $aleph-grid-size;
    }
  }

  &__label {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: $aleph-grid-size;
  }

  .#{$bp-ns}-slider {
    height: $aleph-grid-size * 3;
  }

  .#{$bp-ns}-slider-label {
    font-size: 10px;
  }

  .#{$bp-ns}-collapse-body {
    padding-bottom: $aleph-grid-size;
  }

  &__helptext {
    // Prevents the element from becoming scrollable and thus tabbable when it's collapsed.
    overflow-x: hidden;
  }
}
