@import 'app/variables.scss';
@import 'app/mixins.scss';

.InvestigationOverview {
  &__section {
    position: relative;
    margin: $aleph-content-padding * 0.5 0 $aleph-content-padding * 1.5;

    &__title.#{$bp-ns}-heading {
      margin-bottom: $aleph-content-padding * 0.5;
      padding-bottom: $aleph-grid-size * 0.7;
      border-bottom: 1px solid $aleph-border-color;
    }
  }

  .NotificationList {
    & > .SortingBar {
      position: absolute;
      @include rtl(left, auto, 0);
      @include rtl(right, 0, auto);
      top: -($aleph-grid-size) * 0.7;

      span {
        font-size: $pt-font-size-small;
      }

      .#{$bp-ns}-button {
        border: none;
      }
    }
    .SortingBar__label {
      display: none !important;
    }
  }

  &__guides {
    $guides-button-margin: 7px;

    margin: 0 (-$guides-button-margin * 0.5);

    .#{$bp-ns}-button {
      margin: $guides-button-margin * 0.5;
      background-color: rgba($blue2, 0.09) !important;
      transition: background-color 0.05s ease-in-out;

      &:hover {
        background-color: rgba($blue2, 0.04) !important;
      }
    }
  }
}
