@import 'src/react-ftm/variables.scss';

.PropertyValues {
  @include breakText;

  .EntityLabel {
    display: inline-block;
  }

  .more-text {
    display: block;
    font-size: 11px;
    padding: 0 5px;
  }
}
