@import 'app/variables.scss';

.DocumentUploadDialog {
  &.#{$bp-ns}-dialog {
    padding-bottom: 0;
  }

  &__divider {
    text-align: center;
    padding: $aleph-grid-size 0;
  }
  .document-upload-progress-bar {
    margin-bottom: 1em;
  }

  .#{$bp-ns}-form-group {
    margin: $aleph-grid-size 0;
  }
}
