@import 'app/variables.scss';

.GroupScreen {
  .note {
    color: $aleph-greyed-text;
    margin-top: $aleph-grid-size;
    padding-bottom: $aleph-grid-size * 2;
  }

  .SignInCallout {
    margin-bottom: $aleph-grid-size;
  }
}
