@import 'app/variables.scss';
@import 'app/mixins.scss';

.EntityTable {
  .align-middle {
    vertical-align: middle;
  }

  .Count {
    @include rtlSupportInvertedProp(margin, left, 5px, null);
  }

  .data-grid-container {
    min-height: 300px;
  }
}
