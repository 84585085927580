@import 'app/variables.scss';

.MappingIndex {
  &__items {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin: 4 * $aleph-grid-size 0;
  }
}
