@import 'app/variables.scss';

.BookmarksDrawer__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 2 * $aleph-grid-size;
}

.BookmarksDrawer__content > * + * {
  margin-top: 2 * $aleph-grid-size;
}
