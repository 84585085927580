@import 'react-ftm/variables.scss';

.VertexMenu {
  position: absolute;
  box-shadow: $editor-box-shadow;
  z-index: 100;

  .#{$bp-ns}-spinner {
    padding: 10px 0;
  }

  .#{$bp-ns}-icon {
    color: #5c7080 !important;
  }
}
