@import 'app/variables.scss';

// This overrides Blueprint's default styles for code blocks.
// When bundling our app, Blueprint styles are currently included
// after component-specific styles, i.e. Blueprint styles override
// component-specific styles if the selector specificity is the
// same. The repeated class name in the selector is a hack to increase
// the specificity.
.TextViewer.TextViewer {
  margin: 0;
  padding: $aleph-grid-size;
  border: none;
  box-shadow: none;
}

.TextViewer > em {
  background-color: $aleph-text-highlight-color;
  font-style: inherit;
}
