@import 'app/variables.scss';
@import 'app/mixins.scss';

$notification-items-padding: 1px;

.Notification {
  padding: $aleph-grid-size * 1.3 0;
  line-height: 1.5em;
  border-bottom: 1px solid $aleph-border-color;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  .notification-action {
    .param {
      font-weight: bold;
      .Role {
        span {
          @include rtlSupportInvertedProp(
            padding,
            right,
            $notification-items-padding,
            null
          );
        }
      }
      & > .CollectionLabel {
        font-weight: normal;
        color: $blue2;
      }
    }
    .token {
      @include rtlSupportInvertedProp(
        padding,
        right,
        $notification-items-padding,
        null
      );
    }

    & > * {
      padding: 0 $notification-items-padding;
      display: inline;
    }
  }

  .timestamp {
    color: $aleph-greyed-text;
    white-space: nowrap;
    @include rtlSupportInvertedProp(padding, left, $aleph-grid-size * 2, null);
    @include rtl(text-align, right, left);
  }

  .EntityLabel {
    vertical-align: inherit;
  }

  span.#{$bp-ns}-skeleton {
    white-space: nowrap;
  }
}
