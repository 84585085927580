@import 'react-ftm/variables.scss';

.NetworkDiagram {
  flex: 1;
  display: flex;
  height: 100%;

  &.toolbar-top {
    flex-flow: column;
  }

  &.toolbar-left {
    flex-flow: row;
  }

  &__toolbar {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    z-index: 1;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    overflow: hidden;

    &.sidebar-open {
      flex-flow: row;
    }

    &.table-open {
      flex-flow: column;
    }
  }

  &__main {
    flex-grow: 4;
    flex-shrink: 1;
    flex-basis: auto;
    overflow: hidden;
    display: flex;
    outline: none !important;
    position: relative;

    svg {
      outline: none !important;
    }
  }

  &__sidebar {
    box-sizing: border-box;
    width: $sidebar-width;
    position: relative;
  }

  &__table {
    flex-grow: 4;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
    position: relative;
    z-index: 0;

    .#{$bp-ns}-overlay.#{$bp-ns}-overlay-container.#{$bp-ns}-overlay-inline {
      position: relative;
    }
  }

  &__button-group {
    position: absolute;
    bottom: 10px;
    z-index: 1;

    .toolbar-top & {
      left: 10px;
    }

    .toolbar-left & {
      right: 10px;
    }
  }
}
