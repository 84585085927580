@import 'app/variables.scss';
@import 'app/mixins.scss';

.Breadcrumbs {
  z-index: 1;
  padding: $aleph-grid-size * 0.5 $aleph-content-padding;
  background-color: $aleph-breadcrumbs-background;

  @media screen and (max-width: $aleph-screen-sm-max-width) {
    @include rtlSupportInvertedProp(
      padding,
      left,
      $aleph-mobile-content-padding,
      $aleph-mobile-content-padding
    );
  }

  &.casefile {
    background-color: $aleph-investigation-theme;
  }

  &.dataset {
    background-color: $aleph-dataset-theme;
  }

  &.casefile,
  &.dataset {
    .#{$bp-ns}-breadcrumb {
      color: white;
    }

    .#{$bp-ns}-divider {
      border-color: $light-gray4;
    }
  }

  &__inner-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__main,
  &__right {
    margin: 2px 0;
  }

  &__main {
    @include rtlSupportInvertedProp(margin, right, $aleph-content-padding, 0);
  }

  &__right {
    display: flex;

    .#{$bp-ns}-button-group {
      align-items: center;
    }

    .#{$bp-ns}-divider:last-child {
      display: none;
    }

    .#{$bp-ns}-control-group,
    .#{$bp-ns}-input,
    .#{$bp-ns}-button-group {
      border-radius: $aleph-border-radius !important;
    }

    .#{$bp-ns}-input-group {
      min-width: $aleph-grid-size * 20.5;
    }
  }

  .#{$bp-ns}-breadcrumbs {
    height: auto;
    margin: 1px 0;

    & > li {
      margin: 5px 0;
      &::after {
        margin: 0 10px;
      }
    }
  }

  .#{$bp-ns}-breadcrumb {
    font-size: $aleph-font-size;

    &.CollectionLabel {
      font-weight: 600;

      .#{$bp-ns}-spinner-head {
        stroke: white;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .#{$bp-ns}-breadcrumb-current {
    color: $blue1;

    .#{$bp-ns}-spinner-head {
      stroke: $blue1;
    }
  }

  .EntityLabel > * {
    display: inline-flex !important;
    white-space: nowrap !important;
  }
}
