@import 'app/variables.scss';
@import 'app/mixins.scss';

.EntitySetManageMenu {
  .#{$bp-ns}-input-group {
    border-radius: $aleph-border-radius;
  }

  .#{$bp-ns}-divider {
    margin: $aleph-grid-size * 0.5 !important;
  }
}
