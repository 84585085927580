@import 'app/variables.scss';

.ErrorSection {
  display: table;
  // height: 100%;
  width: 100%;
  min-height: 40vh;

  .inner-div {
    display: table-cell;
    vertical-align: middle;
  }
}
