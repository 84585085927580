@import 'app/variables.scss';
@import 'app/mixins.scss';

.DocumentManager {
  &__content {
    &__empty {
      border: 1px dashed rgba(92, 112, 128, 0.6);
      cursor: pointer;
      max-width: $aleph-content-width-limit;

      .#{$bp-ns}-heading,
      .#{$bp-ns}-button .#{$bp-ns}-icon {
        color: rgba(92, 112, 128, 0.6);
        line-height: 1.5;
      }

      .#{$bp-ns}-button {
        padding: $aleph-content-padding;
      }
    }
  }

  .Count {
    @include rtlSupportInvertedProp(
      margin,
      left,
      $aleph-grid-size * 0.5 !important,
      null
    );
  }

  .align-middle {
    vertical-align: middle;
  }
}
