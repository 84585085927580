@import 'app/variables.scss';

.PagingButtons {
  display: flex;
  width: 100%;
  background-color: $aleph-breadcrumbs-background;
  padding: 3px;

  &__left,
  &__right {
    width: 37.5%;
  }

  &__middle {
    width: 25%;
    background: none;
    cursor: auto;
    text-align: center;
  }

  &__right {
    text-align: end;
  }

  &__input {
    display: inline-block;
    width: 40px;
    input {
      padding: 5px !important;
      text-align: center;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  &__feedback {
    text-decoration: underline;
  }
}
