@import 'app/variables.scss';
@import 'app/mixins.scss';

.Mention {
  text-decoration: none !important;

  .#{$bp-ns}-tag {
    background-color: $aleph-breadcrumbs-background !important;
    margin: 2px 0;

    &:hover {
      background-color: $light-gray2 !important;
    }

    .Count {
      padding: 0 5px 0 8px;
      border: none;
      background: none !important;
    }
  }

  &__text {
    @include breakText;
    overflow: hidden;
    max-width: 100%;
    display: block;
    padding: 0 $aleph-grid-size * 0.25;
  }
}
