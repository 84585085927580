.LoadingScreen {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    align-self: center;
    margin: auto;
  }
}
