@import 'app/variables.scss';
@import 'app/mixins.scss';

.CollectionMetadataPanel {
  display: flex;
  flex-flow: row wrap;
  margin: -$aleph-content-padding * 0.5;

  &__item {
    padding: $aleph-grid-size * 1.5;
    background: $light-gray5;
    border-radius: $aleph-border-radius;
    flex: 1 1 auto;
    margin: $aleph-content-padding * 0.5;

    @media screen and (min-width: $aleph-screen-md-max-width) {
      max-width: $aleph-investigation-sidebar-width - $aleph-content-padding;
    }

    &.mobile-hide {
      @media screen and (max-width: $aleph-screen-md-max-width) {
        display: none;
      }
    }

    .text-markdown p:last-child {
      margin: 0 !important;
    }
  }

  .CollectionStatus {
    font-size: $pt-font-size-small;
    background-color: $light-gray5 !important;

    .#{$bp-ns}-heading {
      font-size: $pt-font-size-large !important;
      margin-bottom: 0 !important;
    }
  }
}
