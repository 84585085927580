@import 'app/variables.scss';

.TimelineActions {
  display: flex;
  gap: $aleph-grid-size;
}

.TimelineActions__feedback {
  color: $aleph-greyed-text;
  text-decoration: underline;
}
