@import 'app/variables.scss';
@import 'app/mixins.scss';

.DocumentViewMode {
  flex: 23;

  .outer {
    flex: 2;
    padding: ($aleph-grid-size * 0.5);

    .inner {
      background: white;
      box-shadow: $pt-elevation-shadow-2;
      max-width: $aleph-screen-md-max-width;
      @include rtlSupportInvertedProp(margin, right, auto, 0);
    }
  }
}
