@import 'app/variables.scss';

.CollectionAccessDialog {
  .#{$bp-ns}-control.#{$bp-ns}-checkbox {
    margin-bottom: 0;
  }

  .header-topic {
    font-weight: bold;
  }

  .#{$bp-ns}-callout {
    margin-top: $aleph-grid-size;
  }
}
