@import 'app/variables.scss';
@import 'app/mixins.scss';

.Restricted {
  &__content {
    @include rtlSupportInvertedProp(margin, left, $aleph-grid-size, null);
  }

  &__message {
    max-width: 25em;
  }

  &__popover {
    .#{$bp-ns}-popover2-arrow-fill {
      fill: rgb(249, 237, 223) !important;
    }
  }
}
