@import 'app/variables.scss';
@import 'app/mixins.scss';

.EntitySetSelectorSection {
  &__title-container {
    margin-bottom: $aleph-grid-size * 0.5;
  }

  &__title {
    &.#{$bp-ns}-button {
      justify-content: flex-start;
      background-color: $aleph-breadcrumbs-background !important;
    }
    padding: $aleph-grid-size * 0.5;
    position: relative;

    .#{$bp-ns}-heading {
      margin: 0;
    }

    .Count {
      position: absolute;
      @include rtl(right, $aleph-grid-size, initial);
      @include rtl(left, initial, $aleph-grid-size);
    }
  }

  &__content {
    padding-bottom: $aleph-grid-size;
  }

  &__create {
    padding: $aleph-grid-size 3px;
  }

  .ErrorSection {
    min-height: 100px;
  }

  .#{$bp-ns}-non-ideal-state {
    .#{$bp-ns}-icon {
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .#{$bp-ns}-non-ideal-state-visual {
      font-size: 30px;
      margin-bottom: $aleph-grid-size;
    }
    .#{$bp-ns}-heading {
      font-size: 16px;
    }
  }
}
