@import 'app/variables.scss';
@import 'app/mixins.scss';

.EntitySetSelector {
  &__portal-container .#{$bp-ns}-overlay.#{$bp-ns}-overlay-container {
    @include rtl(right, 0, auto);
    @include rtl(left, auto, 0);
    overflow: visible;
  }

  .#{$bp-ns}-drawer-header {
    @include rtlSupportInvertedProp(
      padding,
      left,
      $aleph-grid-size * 1.5,
      null
    );
    @include rtlSupportInvertedProp(padding, right, $aleph-grid-size, null);
    z-index: 1;
    .#{$bp-ns}-heading {
      font-size: 16px;
    }
  }

  .#{$bp-ns}-drawer-body {
    width: 100%;
    padding: $aleph-grid-size;

    &.blocking {
      pointer-events: none;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $light-gray5;
    opacity: 0.7;
    z-index: 5;
  }

  &__top-section {
    padding: $aleph-grid-size 0;
  }

  &__section {
    padding: $aleph-grid-size 0;

    &__title.#{$bp-ns}-heading {
      margin: 0;
      font-size: 14px;
    }
  }
}
